html {
  font-size: 12px; /* Slightly increased base font size */
}

#navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding-top: 10px;
  background-color: #121212;
}

.navigation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
  max-width: 800px; /* Match the max-width of .projects-page */
  margin: 0 auto; /* Center the navigation container */
  padding: 0 20px 10px;
  box-sizing: border-box;
  overflow-x: auto;
}


.navigation a {
  text-decoration: none;
  font-size: 1.5em; /* Increased font size */
  color: white;
  padding: 8px 16px;
  transition: all 0.3s ease;
  display: inline-block;
  position: relative;
  overflow: hidden;
  flex: 0 0 auto; /* Prevent shrinking */
}

.navigation a .text-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
}

.navigation a .default,
.navigation a .hover {
  display: block;
  transition: transform 0.3s ease;
}

.navigation a .hover {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.navigation a:hover .default {
  transform: translateY(-100%);
}

.navigation a:hover .hover {
  transform: translateY(-100%);
}

.navigation a.active:hover .text-wrapper {
  color: #000000;
}

/* Adjust the main content to account for the navbar */
.content, .projects-page {
  padding-top: 60px; /* Reduced padding-top to create less space between navbar and content */
}

@media (max-width: 768px) {
  .navigation {
    justify-content: flex-start; /* This is already set, but keeping it for clarity */
    padding-left: 10px;
    padding-right: 10px;
  }
  
  /* Adjust padding for smaller screens */
  .content, .projects-page {
    padding-top: 80px; /* Reduced padding-top for smaller screens */
  }
}

@media (max-width: 480px) {
  .navigation {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  /* Further adjust padding for even smaller screens */
  .content, .projects-page {
    padding-top: 100px; /* Reduced padding-top for even smaller screens */
  }
}
