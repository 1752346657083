@font-face {
  font-family: 'UbuntuMono';
  src: url('./fonts/UbuntuMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'UbuntuMono', monospace;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overlay-navigation a {
  color: rgb(255, 255, 255);
  font-size: 2em;
  margin: 20px 0;
  text-decoration: none;
  transition: color 0.3s;
}

.overlay-navigation a:hover {
  color: rgba(255, 255, 255, 0.7);
}

@media (min-width: 769px) {
  body.menu-open {
    overflow: auto;
  }

  .overlay {
    display: none;
  }
}

@media screen and (max-width: 600px) { 
  h1 { 
    font-size: 1.5em;
    line-height: 1.2;
  }
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 80px 20px;
  box-sizing: border-box;
}

.projects-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#root {
  height: 100%;
}

.section-title {
  text-align: left;
  width: 100%;
  padding-left: 0;
  margin-left: 0;
  align-self: flex-start;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px; /* Adjust this value to bring the content closer to the navigation */
}

.main-content {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}