/* src/index.css */
/* Add your global styles here */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #121212;
}


#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  padding-top: 60px; /* Adjust this to match your navbar height */
}